<div *ngIf="!initLoading; else initLoadingTemplate" class="login">
    <div class="login-area">
        <div class="login-inner">
            <svg-icon
                src="assets/roche_blue.svg"
                [svgStyle]="{ 'width.px': 96 }"
            ></svg-icon>

            <div class="login-header">
                Welcome to Digitalization of the Protocol!
            </div>

            <div *ngIf="!loading; else loginLoading">
                <button
                    class="login-button"
                    rds-primary-button
                    (click)="loginRequest()"
                >
                    Sign in via SSO (for Roche employees)
                </button>
                <div class="login-alert">
                    <rds-alert *ngIf="error" [style]="'error'">
                        {{ errorMessage }}</rds-alert
                    >
                </div>
            </div>
            <ng-template #loginLoading>
                <rds-progress-spinner></rds-progress-spinner>
            </ng-template>
        </div>
    </div>
    <div class="banner-area">
        <div class="banner-main"></div>
        <div class="banner-icon"></div>
    </div>
</div>
<ng-template #initLoadingTemplate>
    <app-loading-page></app-loading-page>
</ng-template>
