import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from 'src/app/service/user.service';

@Injectable()
export class OnlyLoggedInGuard implements CanActivate {
    constructor(
        private oAuthService: OAuthService,
        private userService: UserService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.oAuthService.hasValidAccessToken()) {
            return true;
        } else {
            this.router.navigate(['/login'], {
                queryParams: { redirect: state.url },
                replaceUrl: true,
            });
            return false;
        }
    }
}
