<h2 rds-dialog-header>Terms of Use</h2>
<div rds-dialog-content class="tos">
    <h4 class="tos-change" *ngIf="data.deltaNotification">
        Terms of Use changed since your last visit. Please accept them before
        continuing.
    </h4>
    <ol>
        <li *ngFor="let r of rules.listOfRules">
            {{ r }}
        </li>
    </ol>
    <rds-checkbox
        *ngIf="!data.optional"
        [style.margin-top.px]="20"
        (changed)="rau = $event.checked"
    >
        I acknowledge the Terms of Use
    </rds-checkbox>
</div>
<div rds-dialog-actions-container>
    <button
        *ngIf="!data.optional"
        rds-secondary-button
        [rds-dialog-close]="false"
        size="s"
    >
        Cancel
    </button>
    <button
        *ngIf="!data.optional"
        rds-primary-button
        [rds-dialog-close]="true"
        [disabled]="!rau"
        size="s"
    >
        Accept
    </button>
    <button
        rds-primary-button
        *ngIf="data.optional"
        [rds-dialog-close]="false"
        size="s"
    >
        Close
    </button>
</div>
