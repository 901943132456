import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { take } from 'rxjs';

@Component({
    selector: 'app-error-global-page',
    templateUrl: './error-global-page.component.html',
    styleUrls: ['./error-global-page.component.scss'],
})
export class ErrorGlobalPageComponent {
    public errorDescription = '';
    public errorSubtitle = '';
    public errorTitle = '';

    constructor(private route: ActivatedRoute) {
        this.route.data.pipe(take(1)).subscribe({
            next: (data) => {
                this.errorTitle = data['errorTitle'];
                this.errorSubtitle = data['errorSubtitle'];
            },
        });
    }
}
