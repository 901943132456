import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    catchError,
    firstValueFrom,
    from,
    lastValueFrom,
    throwError,
} from 'rxjs';

import { UserCredentials } from './model/userIdentity.model';
import { UserService } from './service/user.service';
import { authCodeFlowConfig } from './utility/auth-flow';
import { magicLog } from './utility/helpers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private accessToken = '';
    private idToken = '';
    private issuer = '';

    constructor(private userService: UserService) {}

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.accessToken,
            'Authorization-Client': this.issuer,
            'Authorization-Identity': this.idToken,
        });
        if (
            !(this.accessToken && this.idToken && this.issuer) &&
            authCodeFlowConfig.issuer &&
            req.url.indexOf(authCodeFlowConfig.issuer) === -1 &&
            req.url.indexOf('/assets/') === -1
        ) {
            const credentials = (await firstValueFrom(
                this.userService.userCredentials
            )) as UserCredentials;
            if (credentials) {
                this.accessToken = credentials.accessToken;
                this.idToken = credentials.idToken;
                this.issuer = credentials.clientId;
            }

            headers = new HttpHeaders({
                Authorization: 'Bearer ' + this.accessToken,
                'Authorization-Client': this.issuer,
                'Authorization-Identity': this.idToken,
            });

            const cloneReq = req.clone({ headers });
            return await lastValueFrom(next.handle(cloneReq));
        } else {
            if (req.url.indexOf('/assets/') > -1) {
                return await lastValueFrom(next.handle(req));
            } else {
                if (this.accessToken && this.idToken && this.issuer) {
                    const cloneReq = req.clone({ headers });
                    return await lastValueFrom(next.handle(cloneReq));
                } else {
                    return await lastValueFrom(next.handle(req));
                }
            }
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        return from(this.handle(req, next)).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.userService.sessionExpiredCommandRequest();

                        return throwError(() => new Error('Session expired'));
                    }
                    return throwError(() => err);
                } else {
                    return throwError(() => err);
                }
            })
        );
    }
}
