import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ProtocolHit } from '../model/api.model';
import { ComparatorSelectionList } from '../model/protocol-search.model';

@Injectable({ providedIn: 'root' })
export class ComparatorService {
    public _selectedStudies$: BehaviorSubject<ComparatorSelectionList> =
        new BehaviorSubject<ComparatorSelectionList>({});

    public selectedStudies = this._selectedStudies$.asObservable();

    public deselectAllStudies() {
        this._selectedStudies$.next({});
        this.storeAll();
    }

    public deselectStudies(studies: ProtocolHit[]) {
        const copy = structuredClone(this._selectedStudies$.getValue());
        studies.forEach((study) => {
            if (copy[study.Source.studyNumber]) {
                delete copy[study.Source.studyNumber];
            }
        });
        this._selectedStudies$.next(copy);
        this.storeAll();
    }
    public deselectStudy(study: ProtocolHit | string) {
        const copy = structuredClone(this._selectedStudies$.getValue());
        let num: string;
        if (typeof study === 'string' || study instanceof String) {
            num = <string>study;
        } else {
            num = study.Source.studyNumber;
        }
        if (copy[num]) {
            delete copy[num];
            this._selectedStudies$.next(copy);
            this.storeAll();
        }
    }
    public loadAll() {
        const payload = localStorage.getItem('comparatorStorage');
        if (payload) {
            const studies = JSON.parse(payload);
            if (studies && Object.keys(studies).length > 0) {
                this._selectedStudies$.next(studies as ComparatorSelectionList);
            }
        }
    }
    public selectStudies(studies: ProtocolHit[]) {
        const copy = structuredClone(this._selectedStudies$.getValue());
        studies.forEach((study) => {
            if (!copy[study.Source.studyNumber]) {
                copy[study.Source.studyNumber] = study;
            }
        });
        this._selectedStudies$.next(copy);
        this.storeAll();
    }
    public selectStudy(study: ProtocolHit) {
        const copy = structuredClone(this._selectedStudies$.getValue());
        if (!copy[study.Source.studyNumber]) {
            copy[study.Source.studyNumber] = study;
            this._selectedStudies$.next(copy);
            this.storeAll();
        }
    }
    public storeAll() {
        try {
            localStorage.setItem(
                'comparatorStorage',
                JSON.stringify(this._selectedStudies$.value)
            );
        } catch (error) {
            console.warn(error);
        }
    }
}
