import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Provider, forwardRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

import {
    RdsAvatarModule,
    RdsBadgeModule,
    RdsButtonModule,
    RdsCheckboxModule,
    RdsDividerModule,
    RdsHeaderModule,
    RdsIconsModule,
    RdsMenuModule,
    RdsProgressSpinnerModule,
    RdsSidenavModule,
    RdsToastModule,
} from '@rds/angular-components';
import {
    RDS_ICON_LOADER_CONFIG,
    RdsAlertModule,
    RdsDialogModule,
    RdsIconLoaderConfig,
} from '@rds/angular-components';

import { OAuthModule } from 'angular-oauth2-oidc';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LottieModule } from 'ngx-lottie';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './auth.interceptor';
import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { ErrorGlobalPageComponent } from './page/error-global-page/error-global-page.component';
import { LoadingPageComponent } from './page/loading-page/loading-page.component';
import { LoginComponent } from './page/login/login.component';
import { DisclaimerDialogComponent } from './page/start/disclaimer-dialog/disclaimer-dialog.component';
import { NoAccessDialogComponent } from './page/start/no-access-dialog/no-access-dialog.component';
import { SessionExpiredDialogComponent } from './page/start/session-expired-dialog/session-expired-dialog.component';
import { OnlyLoggedInGuard } from './utility/guards/logged-in.guard';

export const API_INTERCEPTOR_PROVIDER: Provider = {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(() => AuthInterceptor),
};

@NgModule({
    declarations: [
        AppComponent,
        MainLayoutComponent,
        EmptyLayoutComponent,
        LoginComponent,
        LoadingPageComponent,
        ErrorGlobalPageComponent,
        DisclaimerDialogComponent,
        NoAccessDialogComponent,
        SessionExpiredDialogComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        RdsDividerModule,
        RdsHeaderModule,
        RdsDialogModule,
        RdsCheckboxModule,
        RdsAvatarModule,
        RdsAlertModule,
        RdsIconsModule,
        RdsButtonModule,
        RdsSidenavModule,
        RdsBadgeModule,
        RdsMenuModule,
        RdsToastModule,
        LottieModule.forRoot({ player: () => import('lottie-web') }),
        RdsProgressSpinnerModule,
        AngularSvgIconModule.forRoot(),
        OAuthModule.forRoot(),
        OverlayModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: RDS_ICON_LOADER_CONFIG,
            useValue: {
                preloadSelected: 'all',
            } as RdsIconLoaderConfig,
        },
        API_INTERCEPTOR_PROVIDER,
        AuthInterceptor,
        provideAnimations(),
        OnlyLoggedInGuard,
    ],
})
export class AppModule {}
