import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RdsDialogService } from '@rds/angular-components';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { first, skip, take } from 'rxjs';

import { DisclaimerDialogComponent } from './page/start/disclaimer-dialog/disclaimer-dialog.component';
import { NoAccessDialogComponent } from './page/start/no-access-dialog/no-access-dialog.component';
import { SessionExpiredDialogComponent } from './page/start/session-expired-dialog/session-expired-dialog.component';
import { ComparatorService } from './service/comparator.service';
import { UserService } from './service/user.service';
import { authCodeFlowConfig } from './utility/auth-flow';

function isLoginScreen(): boolean {
    const absoluteUrlRoot = window.location.href.split('/');
    if (
        absoluteUrlRoot[3] &&
        absoluteUrlRoot[3].split('?')[0].indexOf('login') > -1
    ) {
        return true;
    }
    return false;
}

function isRootPage(): boolean {
    const absoluteUrlRoot = window.location.href.split('/');
    if (
        absoluteUrlRoot[3] === '' ||
        absoluteUrlRoot[3].split('?')[0].length === 0 ||
        absoluteUrlRoot[3].split('#')[0].length === 0
    ) {
        return true;
    }
    return false;
}

function isLegacyPage(): boolean {
    const absoluteUrlRoot = window.location.href.split('/');
    if (
        absoluteUrlRoot[3] === 'start' ||
        absoluteUrlRoot[3].split('?')[0].length === 0 ||
        absoluteUrlRoot[3].split('#')[0].length === 0
    ) {
        return true;
    }
    if (
        absoluteUrlRoot[3] === 'home' ||
        absoluteUrlRoot[3].split('?')[0].length === 0 ||
        absoluteUrlRoot[3].split('#')[0].length === 0
    ) {
        return true;
    }
    if (
        absoluteUrlRoot[3] === 'protocol-search' ||
        absoluteUrlRoot[3].split('?')[0].length === 0 ||
        absoluteUrlRoot[3].split('#')[0].length === 0
    ) {
        return true;
    }
    return false;
}

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public appEnabled = false;
    public title = 'dop-frontend';

    constructor(
        private oAuthService: OAuthService,
        public router: Router,
        public route: ActivatedRoute,
        private userService: UserService,
        private comparatorService: ComparatorService,
        private dialogService: RdsDialogService
    ) {
        if (import.meta.env.NG_APP_HASH) {
            console.log(
                '%c DOP Frontend version ' + import.meta.env.NG_APP_HASH,
                'color: #06C'
            );
        } else {
            console.log('%c DOP Frontend version LOCAL', 'color: #FF0');
        }

        this.oAuthService.configure(authCodeFlowConfig);
    }

    public async ngOnInit() {
        let loginSkipBlock = false;

        if (window.location.search.indexOf('?code=') === 0) {
            const payload = Object.fromEntries(
                new URL(window.location.href).searchParams.entries()
            ) as any;
            this.appEnabled = true;
            this.router.navigate(['/login'], {
                queryParams: {
                    code: payload.code,
                    state: payload.state,
                },
                replaceUrl: true,
            });
        } else {
            if (this.oAuthService.hasValidAccessToken()) {
                this.userService.establishSession();
                if (isRootPage()) {
                    this.router.navigate(['/sil/start'], { replaceUrl: true });
                }
                if (isLegacyPage()) {
                    this.router.navigate(['/sil/start'], { replaceUrl: true });
                }
            } else {
                if (isRootPage() || isLegacyPage()) {
                    this.appEnabled = true;
                    loginSkipBlock = true;
                    this.router.navigate(['/login'], { replaceUrl: true });
                } else {
                    const optionalPayload = {
                        queryParams: {
                            redirect: window.location.pathname,
                        },
                    };
                    this.router.navigate(
                        ['/login'],
                        window.location.pathname.length > 1 &&
                            window.location.pathname !== '/login'
                            ? optionalPayload
                            : undefined
                    );
                    loginSkipBlock = true;
                    this.appEnabled = true;
                }
            }
        }

        console.log('AO');

        if (!loginSkipBlock) {
            console.log('CO');
            this.loadApp();
        } else {
            this.loadApp();
        }
    }

    private loadApp() {
        this.oAuthService.setupAutomaticSilentRefresh();
        this.userService.sessionExpiredCommand.pipe(skip(1)).subscribe(() => {
            const dialogRef = this.dialogService.open(
                SessionExpiredDialogComponent,
                {
                    ariaLabel: 'Session Expired',
                    closeOnNavigation: false,
                    disableClose: true,
                } as any
            );
            dialogRef.afterClosed().subscribe((result) => {
                if (result === true) {
                    this.userService.logout();
                }
            });
        });
        this.userService.identityEstablished
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (status) => {
                    // Allow only enabling if the app is disabled.
                    if (!this.appEnabled && status) {
                        this.appEnabled = true;
                    }
                },
            });

        this.comparatorService.loadAll();
    }
}
