import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { UserCredentials, UserIdentity } from '../model/userIdentity.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    public _identityEstablished$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public _sessionExpiredCommand$: BehaviorSubject<null> =
        new BehaviorSubject<null>(null);
    public _userCredentials$: BehaviorSubject<UserCredentials | null> =
        new BehaviorSubject<UserCredentials | null>(null);
    public _userIdentity$: BehaviorSubject<UserIdentity | null> =
        new BehaviorSubject<UserIdentity | null>(null);

    protected applicationAccessDenied = false;

    public identityEstablished = this._identityEstablished$.asObservable();
    public sessionExpiredCommand = this._sessionExpiredCommand$.asObservable();
    public userCredentials = this._userCredentials$.asObservable();

    public userIdentity = this._userIdentity$.asObservable();

    constructor(
        private oAuthService: OAuthService,
        private router: Router
    ) {}

    get applicationAccess() {
        return !this.applicationAccessDenied;
    }

    public establishSession(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.oAuthService.hasValidAccessToken()) {
                const interval = setInterval(() => {
                    if (
                        !this.oAuthService.hasValidAccessToken() &&
                        !this.applicationAccessDenied
                    ) {
                        clearInterval(interval);
                        this.sessionExpiredCommandRequest();
                    }
                }, 5000);
                try {
                    this.instantiate(
                        this.oAuthService.getAccessToken(),
                        this.oAuthService.getIdToken(),
                        this.oAuthService.getAccessTokenExpiration()
                    );
                    resolve(true);
                } catch (ex) {
                    reject(ex);
                }
            }
            reject(false);
        });
    }

    public instantiate(
        accessToken: string,
        idToken: string,
        expires: number
    ): void {
        const aToken = jwt_decode(accessToken) as any;
        const iToken = jwt_decode(idToken) as any;

        const parseStringCnEntry = (groupEntry: string) => {
            let found = '';
            const commaSeparated = groupEntry.split(',');
            commaSeparated.forEach((groupProperty: string) => {
                const equalParsed = groupProperty.split('=');
                if (equalParsed[0] === 'CN') {
                    found = equalParsed[1];
                }
            });
            return found;
        };

        const parseCnGroups = (groupsArray: string[]): string[] => {
            const parsed: string[] = [];
            if (!groupsArray) {
                return parsed;
            }
            if (!!groupsArray && !Array.isArray(groupsArray)) {
                parsed.push(parseStringCnEntry(groupsArray));
                return parsed;
            }
            groupsArray.forEach((groupEntry: string) => {
                parsed.push(parseStringCnEntry(groupEntry));
            });
            return parsed;
        };

        const userGroups = parseCnGroups(aToken.member_of);

        if (userGroups) {
            if (
                userGroups.indexOf(import.meta.env.NG_APP_OIDC_ACCESS_GROUP) < 0
            ) {
                this.applicationAccessDenied = true;
            }
        } else {
            this.applicationAccessDenied = true;
        }

        this._identityEstablished$.next(true);

        this._userIdentity$.next({
            email: aToken.email,
            givenName: aToken.givenName,
            lastName: aToken.lastname,
            name: aToken.name,
            sub: aToken.sub,
        });
        this._userCredentials$.next({
            accessToken: accessToken,
            clientId: iToken.aud,
            expiresIn: expires,
            idToken: idToken,
            relevantGroups: userGroups,
        });
    }

    public logout(expired = false, navigate = true): void {
        sessionStorage.clear();
        this._identityEstablished$.next(false);
        this._userIdentity$.next(null);
        this._userCredentials$.next(null);
        if (navigate) {
            if (expired) {
                sessionStorage.setItem('justExpired', 'true');
            }
            this.router.navigate(['/']);
            setTimeout(() => {
                window.location.reload();
            }, 5);
        }
    }

    public sessionExpiredCommandRequest() {
        this._sessionExpiredCommand$.next(null);
    }
}
