import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { ErrorGlobalPageComponent } from './page/error-global-page/error-global-page.component';
import { LoadingPageComponent } from './page/loading-page/loading-page.component';
import { LoginComponent } from './page/login/login.component';
import { OnlyLoggedInGuard } from './utility/guards/logged-in.guard';

export const appRoutes: Routes = [
    { path: '', component: LoadingPageComponent, pathMatch: 'full' },
    { path: 'start', component: LoadingPageComponent, pathMatch: 'full' },
    { path: 'logging-out', component: LoadingPageComponent, pathMatch: 'full' },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'sil',
        canActivate: [OnlyLoggedInGuard],
        children: [
            {
                path: 'start',
                loadChildren: () =>
                    import('./page/start/start.module').then(
                        (m) => m.StartModule
                    ),
            },
            {
                path: 'protocol-search',
                loadChildren: () =>
                    import(
                        './page/protocol-search/protocol-search.module'
                    ).then((m) => m.ProtocolSearchModule),
            },
            {
                path: 'studi-insights',
                loadChildren: () =>
                    import('./page/insights/insights.module').then(
                        (m) => m.InsightsModule
                    ),
            },
        ],
        component: MainLayoutComponent,
    },
    {
        path: '**',
        component: ErrorGlobalPageComponent,
        data: { errorSubtitle: 'Page not found', errorTitle: '404' },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            anchorScrolling: 'enabled',
            useHash: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
