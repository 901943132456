import { Component, inject } from '@angular/core';

import { RDS_DIALOG_DATA } from '@rds/angular-components';

import { TermsOfServiceConfiguration } from 'src/app/terms-of-service.config';

@Component({
    selector: 'app-disclaimer-dialog',
    templateUrl: './disclaimer-dialog.component.html',
    styleUrls: ['./disclaimer-dialog.component.scss'],
})
export class DisclaimerDialogComponent {
    protected readonly data = inject(RDS_DIALOG_DATA) as {
        deltaNotification: boolean;
        optional: boolean;
    };
    public rau = false;
    public rules = TermsOfServiceConfiguration;
}
