<h2 rds-dialog-header>Request access</h2>
<div rds-dialog-content class="noa">
    Your account does not have access to DOP suite. Please request the access
    via RoSE portal.
</div>
<div rds-dialog-actions-container>
    <a
        href="https://roche.service-now.com/rose?id=nr_sc_cat_item&table=sc_cat_item&sys_id=5f12eba78790399c159e32640cbb3592"
        ><button rds-primary-button size="s">Request Access</button></a
    >
</div>
