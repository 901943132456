import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RdsDialogService, RdsToastService } from '@rds/angular-components';

import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from 'src/app/service/user.service';
import { TermsOfServiceConfiguration } from 'src/app/terms-of-service.config';
import { authCodeFlowConfig } from 'src/app/utility/auth-flow';
import { sha256 } from 'src/app/utility/helpers';

import { DisclaimerDialogComponent } from '../start/disclaimer-dialog/disclaimer-dialog.component';
import { NoAccessDialogComponent } from '../start/no-access-dialog/no-access-dialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    private readonly rdsToastService = inject(RdsToastService);
    public error = false;
    public errorMessage = '';
    public initLoading = true;
    public loading = true;

    constructor(
        private oAuthService: OAuthService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialogService: RdsDialogService
    ) {}

    public async login() {
        this.error = false;
        this.errorMessage = 'Unknown error';

        const continueFunction = () => {
            setTimeout(() => {
                const redirect = decodeURIComponent(
                    this.oAuthService.state as string
                );
                if (redirect.length > 0) {
                    this.router.navigate([redirect], {
                        replaceUrl: true,
                    });
                } else {
                    this.router.navigate(['/sil/start'], {
                        replaceUrl: true,
                    });
                }
            }, 500);
        };

        const checkDelta = async (): Promise<{
            agreed: boolean;
            agreedToDelta: boolean;
            delta: string;
        }> => {
            const agreed =
                localStorage.getItem('agreedToTerms') === 'true' ? true : false;
            const delta = await sha256(
                JSON.stringify(TermsOfServiceConfiguration.listOfRules)
            );
            const deltaOnSite = localStorage.getItem('agreedToDelta');
            let agreedToDelta = false;

            if (
                (deltaOnSite && deltaOnSite === delta) ||
                !TermsOfServiceConfiguration.forceReapprovalOnChange
            ) {
                agreedToDelta = true;
            }

            return { agreed, agreedToDelta, delta };
        };

        this.activatedRoute.queryParamMap.subscribe({
            next: (paramMap) => {
                this.oAuthService
                    .loadDiscoveryDocumentAndLogin({
                        customHashFragment: window.location.search,
                        ...(paramMap.has('redirect') && {
                            state: paramMap.get('redirect') as string,
                        }),
                    })
                    .then(async (status) => {
                        if (status) {
                            try {
                                await this.userService.establishSession();

                                const groupAccessToApplicationConfirmed =
                                    this.userService.applicationAccess;

                                if (!groupAccessToApplicationConfirmed) {
                                    const dialogRef = this.dialogService.open(
                                        NoAccessDialogComponent,
                                        {
                                            ariaLabel: 'No access',
                                            closeOnNavigation: false,
                                            disableClose: true,
                                            width: '600px',
                                        } as any
                                    );
                                    sessionStorage.clear();
                                    return;
                                }

                                const deltaCheck: {
                                    agreed: boolean;
                                    agreedToDelta: boolean;
                                    delta: string;
                                } = await checkDelta();

                                if (
                                    !deltaCheck.agreed ||
                                    !deltaCheck.agreedToDelta
                                ) {
                                    const dialogRef = this.dialogService.open(
                                        DisclaimerDialogComponent,
                                        {
                                            ariaLabel: 'Terms of Use',
                                            closeOnNavigation: false,
                                            data: {
                                                deltaNotification:
                                                    deltaCheck.agreed &&
                                                    !deltaCheck.agreedToDelta,
                                            },
                                            disableClose: true,
                                            width: '600px',
                                        } as any
                                    );
                                    dialogRef
                                        .afterClosed()
                                        .subscribe((result) => {
                                            if (result === true) {
                                                localStorage.setItem(
                                                    'agreedToTerms',
                                                    'true'
                                                );
                                                localStorage.setItem(
                                                    'agreedToDelta',
                                                    deltaCheck.delta
                                                );
                                                continueFunction();
                                            } else {
                                                localStorage.setItem(
                                                    'agreedToTerms',
                                                    'false'
                                                );
                                                localStorage.setItem(
                                                    'agreedToDelta',
                                                    ''
                                                );
                                                this.userService.logout();
                                            }
                                        });
                                } else {
                                    continueFunction();
                                }
                            } catch (ex: any) {
                                console.error(ex);
                                this.handleError(ex.message);
                            }
                        }
                    })
                    .catch((err) => {
                        this.error = true;
                        console.error(err);
                    });
            },
            error: (err) => {
                this.error = true;
                console.error(err);
                if (
                    !authCodeFlowConfig.issuer ||
                    !authCodeFlowConfig.clientId ||
                    !authCodeFlowConfig.dummyClientSecret ||
                    !authCodeFlowConfig.scope ||
                    !authCodeFlowConfig.redirectUri
                ) {
                    this.errorMessage =
                        'Critical configuration error. Login disabled';
                }
            },
        });
    }

    public loginRequest() {
        this.login();
    }

    ngOnInit(): void {
        if (
            window.location.search.indexOf('?code=') === 0 ||
            this.oAuthService.hasValidAccessToken()
        ) {
            this.login();
        } else {
            this.initLoading = false;
            this.loading = false;
        }

        if (sessionStorage.getItem('justExpired') === 'true') {
            this.rdsToastService.error(
                'Session expired',
                'Please log in again'
            );
            sessionStorage.setItem('justExpired', 'false');
        }
    }

    private handleError(text: string) {
        // this.messageService.showMessage(text, MESSAGE_TYPE.ERROR);
        this.error = true;
        this.loading = false;
        this.errorMessage = text;
    }
}
