import { format, parseISO } from 'date-fns';

export function arrayMove(
    arr: Array<unknown>,
    old_index: number,
    new_index: number
) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export function magicLog(desc: string, ...args: any) {
    if (Array.isArray(args) && args.length > 0) {
        console.log(
            '%c ' + desc.toUpperCase(),
            'color: #FF0',
            ...structuredClone(args)
        );
    } else {
        console.log('%c ' + desc.toUpperCase(), 'color: #AFF');
    }
}

export function formatDate(date: string): string {
    if (
        date === '1911-11-11T00:00:00' ||
        date === '1911-11-01T00:00:00' ||
        date === null ||
        date === undefined ||
        date === ''
    ) {
        return '-';
    } else {
        const parsed = parseISO(date);
        return `${format(parsed, 'dd MMM yyyy')}`; //(${formatDistance(
        //    parsed,
        //    new Date()
        //)} ago)`;
    }
}

export function getAlphaString(str: string) {
    return str.replace(/[^\w]/gi, '');
}

export function getHtmlIdSafe(str: string) {
    return 'O' + getAlphaString(str).toUpperCase();
}

export function allProgress(
    proms: Promise<unknown>[],
    progressCallback: (status: number) => void
) {
    let d = 0;
    progressCallback(0);
    for (const p of proms) {
        p.then(() => {
            d++;
            progressCallback((d * 100) / proms.length);
        });
    }
    return Promise.all(proms);
}

export async function sha256(message: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
}
